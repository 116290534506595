import React from 'react';
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaTiktok } from "react-icons/fa";
import { AiOutlineYoutube } from "react-icons/ai";

const SocialIcons = () => {
  return (
    <div className='socialicon-container'>
        <div className='social-icon'><a href="https://www.facebook.com/share/RveE8VDX7WURQRs9/" target="_blank" rel="noreferrer" className='a-social' ><FaFacebookF /></a></div>
        <div className='social-icon'><FaLinkedinIn /></div>
        <div className='social-icon'> <a href="https://www.instagram.com/voxxsys_international/profilecard/?igsh=MWdxZHF4dDg5Mmdkdw==" target="_blank" rel="noreferrer" className='a-social'><FaInstagram /></a></div>
        <div className='social-icon'><AiOutlineYoutube /></div>
        <div className='social-icon'><FaTiktok /></div>
    </div>
  )
}

export default SocialIcons;