import React, { useEffect, useRef, useState } from 'react';
import teamMis from '../assest/company/Team Misfitz Logo.png';
import api from '../assest/company/api.png';
import colf from '../assest/company/colf logo.png';
import diyaulpatha from '../assest/company/diyaulpatha.png';
import ethera from '../assest/company/ethera.jpg';
import goldentouch from '../assest/company/goldentouch.jpg';
import greenwell from '../assest/company/greenwell.png';
import idevice from '../assest/company/idevice.png';
import its from '../assest/company/its.png';
import meetcarlos from '../assest/company/meetcarlos.png';
import metrocellular from '../assest/company/metrocellular.png';
import mobilemart from '../assest/company/mobilemart.jpg';
import mobilemax from '../assest/company/mobilemax.png';
import mrchef from '../assest/company/mrchef.jpg';
import mrprint from '../assest/company/mrprint.png';
import nadeeshoe from '../assest/company/nadeeshoe.png';
import nexwin from '../assest/company/nexwin.png';
import nirmanacargo from '../assest/company/nirmanacargo.png';
import orangeseeds from '../assest/company/orangeseeds.jpg';
import onetell from '../assest/company/onetell.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import { Navigation, Pagination } from 'swiper/modules';

const Company = () => {
    const images = [teamMis, api, colf, diyaulpatha, ethera, goldentouch, greenwell, idevice, its, meetcarlos, metrocellular, mobilemart, mobilemax, mrchef, mrprint, nadeeshoe, nexwin, nirmanacargo, orangeseeds, onetell];
    const imagesSlice = images.slice(0, 7)
    // const [startLargeIndex, setStartLargeIndex] = useState(0);
    // const [startMediumIndex, setStartMediumIndex] = useState(0);
    // const [startMobileIndex, setStartMobileIndex] = useState(0);
    // const imagesSliceLarge = images.slice(startLargeIndex, startLargeIndex+7);
    // const imagesSliceMedium = images.slice(startMediumIndex, startMediumIndex+5);
    // const imagesSliceMobile = images.slice(startMobileIndex, startMobileIndex+2);
    
    // useEffect(() => {
    //     const interval1 = setInterval(() => {
    //         if(images.length > startLargeIndex+7) {
    //                 setStartLargeIndex(startLargeIndex+1);
    //         } else {
    //             setStartLargeIndex(0);
    //         }
    //     }, 2000);
    //     return () => clearInterval(interval1);
    // }, [startLargeIndex, images.length]);


    return (
        <div className='company-wrapper'>
            <div className='title'><span className='title-y'>Our </span> Most Trusted Clients</div>
            {/* <div className='tech-container company-large'>
                {imagesSliceLarge.map((item, index) => (
                    <div className='tech-box company'>
                        <img src={item} alt="technologies"/>
                    </div>
                ))} 
            </div>
            <div className='tech-container company-medium'>
                {imagesSliceMedium.map((item, index) => (
                    <div className='tech-box company'>
                        <img src={item} alt="technologies"/>
                    </div>
                ))} 
            </div>
            <div className='tech-container company-mobile'>
                {imagesSliceMobile.map((item, index) => (
                    <div className='tech-box'>
                        <img src={item} alt="technologies"/>
                    </div>
                ))} 
            </div> */}
            <div className='company-container'>
              <Swiper
                modules={[Autoplay]}
                spaceBetween={10}                // Space between slides
                // slidesPerView={7}                // Display 7 slides at a time
                autoplay={{ delay: 2000 }}       // Autoplay settings
                navigation
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
                loop={true}
                marginLeft={0}
                marginRight={0}
                marginTop={0}
                marginBotom={0}
                breakpoints={{ 
                    // 350: {slidesPerView: 1},
                    280: {slidesPerView: 2},
                    640: { slidesPerView: 3 },
                    800: { slidesPerView: 5 },
                    1300: { slidesPerView: 7 }
                }}
                // onSwiper={(swiper) => (swiper2Ref.current = swiper)}
                >
                {images.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div className="company-image-card">
                            <img src={item} alt="company-img" className='company-img'/>
                        </div>
                    </SwiperSlide>
                ))}
                </Swiper>
            </div>
        </div>
    )
}

export default Company;