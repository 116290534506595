import React, { useState } from 'react';
import Slider from '../components/slider/Slider';
import image from '../assest/image.jpg';
import customeBusiness from '../assest/services/customBusiness.png';
import api from '../assest/services/api.png';
import cloud from '../assest/services/cloud.png';
import mobileApp from '../assest/services/MobileApp.png';
import erp from '../assest/services/erp.png';
import webDevelopment from '../assest/services/webDevelopment.png';
import eCommerce from '../assest/services/e-commerce.png';
import a from '../assest/services/Icons-08.png';
import socialMedia from '../assest/services/socialMedia.png';
import digitalContent from '../assest/services/digitalContent.png';

const ProductServices = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const images = [
    {
      src: customeBusiness,
      title: "Custom Business Software Solutions",
    },
    {
      src: api,
      title: "API Development",
    },
    {
      src: cloud,
      title: "Cloud-Based POS Systems",
    },
    {
      src: mobileApp,
      title: "Mobile App Development",
    },
    {
      src: erp,
      title: "ERP/CRM/HRM Systems",
    },
    {
      src: webDevelopment,
      title: "Web Design And Development",
    },
    {
      src: eCommerce,
      title: "E-Commerce Platforms",
    },
    {
      src: a,
      title: "Web Applications",
    },
    {
      src: socialMedia,
      title: "Social Media Management",
    },
    {
      src: digitalContent,
      title: "Digital Content Creating",
    },
  ];

  
  const handleNextImg = () => {
    if(images.length > slideIndex) {
      setSlideIndex(slideIndex+1);
    }
  }

  const handlePrevImg = () => {
    if(slideIndex !== 0) {
      setSlideIndex(slideIndex-1);
    }
  }

  return (
    <div className='main-container service-container' id="productId">
        <div className='title'>What {" "}<span className='title-y'>We </span>Offer</div>
        <div className='main-para'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed</div>
        <div className='service-sub-container'>
          {images.map(item => (
            <div className='service-img-div'>
              <img src={item.src} alt={item.title} className='service-img'/>
              <div className='service-title'>{item.title}</div>
            </div>
          ))}
        </div>
        <div className='service-sub-container-mobile'>
          <div class="preview-button-left" onClick={handlePrevImg}>&#10095;</div>
          <div class="preview-button-right" onClick={handleNextImg}>&#10094;</div>
          <Slider setSlideIndex={setSlideIndex} slideIndex={slideIndex}>
            {images.map((item, index) => (
              <div key={index} className='service-img-div'>
                <img src={item.src} alt={item.title} className='service-img' />
                <div className='service-title'>{item.title}</div>
              </div>
            ))}
          </Slider>
        </div>
    </div>
  )
}

export default ProductServices;