import React from 'react';
import angular from '../assest/tech/angular.png';
import flutter from '../assest/tech/Flutter.png';
import oracle from '../assest/tech/oracle.png';
import Azure from '../assest/tech/Azure.png';
import firebase from '../assest/tech/Firebase.png';
import googleCloud from '../assest/tech/GoogleCloud.jpg';
import aws from '../assest/tech/aws.png';
import css from '../assest/tech/css.png';
import github from '../assest/tech/github.png';
import html from '../assest/tech/html.png';
import java from '../assest/tech/java.png';
import js from '../assest/tech/js.png';
import kotlin from '../assest/tech/kotlin.jpg';
import laravel from '../assest/tech/laravel.png';
import net from '../assest/tech/net.jpg';
import node from '../assest/tech/node.png';
import react from '../assest/tech/react.png';
import scrum from '../assest/tech/scrum.png';
import spring from '../assest/tech/spring.png';
import swift from '../assest/tech/swift.png';
import trello from '../assest/tech/trello.png';

const Tech = () => {
    const images1 = [oracle, flutter, angular, kotlin, googleCloud, Azure];
    const images2 = [firebase, java, node, laravel, html, css, js, react,  aws];
    const images3 = [github, net, scrum, swift, spring, trello ];
    // const images = [angular, flutter , oracle, Azure, java, kotlin, github, html, js, node, react, scrum, spring, swift, trello, net, laravel, css, googleCloud, aws, firebase];
  return (
    <div className='tech-wrapper'>
        <div className='title'>Technology Stack</div>
        <div className='tech-container1'>
            {images1.map((item, index) => (
                <div className='tech-box1'>
                    <img src={item} alt="technologies"/>
                </div>
            ))} 
        </div>
        <div className='tech-container1 tech-container2'>
            {images2.map((item, index) => (
                <div className='tech-box1 tech-box2'>
                    <img src={item} alt="technologies"/>
                </div>
            ))} 
        </div>
        <div className='tech-container1'>
            {images3.map((item, index) => (
                <div className='tech-box1'>
                    <img src={item} alt="technologies"/>
                </div>
            ))} 
        </div>
    </div>
  )
}

export default Tech;