import React, { useEffect } from 'react';
import { IoIosStar } from "react-icons/io";
import { IoIosStarOutline } from "react-icons/io";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import person from '../assest/team/person.png';

const Feedback = () => {
    const swiperRef = React.useRef(null);
    const data = [
        { "id": 1,  "text": "Great service, very satisfied!", "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocLpL2Jer5Gz1JrskHzbpLpYCFggzbq3bZolbgoSNo3s9gBlvw=s128-c0x00000000-cc-rp-mo", "author_name": "Pasindu Gihan", "rating": 5, "relative_time_description": "a week ago" },
        { "id": 2, "author_name": "Bob Jones", "text": "Quick response time and helpful support. Response was a bit slow but overall good experience. Response was a bit slow but overall good experience.", "rating": 4, "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocLpL2Jer5Gz1JrskHzbpLpYCFggzbq3bZolbgoSNo3s9gBlvw=s128-c0x00000000-cc-rp-mo", "relative_time_description": "two week ago" },
        { "id": 3, "author_name": "Charlie Brown", "text": "The product quality could be improved.", "rating": 3, "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocLpL2Jer5Gz1JrskHzbpLpYCFggzbq3bZolbgoSNo3s9gBlvw=s128-c0x00000000-cc-rp-mo", "relative_time_description": "yesterday" },
        { "id": 4, "author_name": "Dana White", "text": "Excellent user experience, would recommend!", "rating": 2 , "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocLpL2Jer5Gz1JrskHzbpLpYCFggzbq3bZolbgoSNo3s9gBlvw=s128-c0x00000000-cc-rp-mo", "relative_time_description": "today" },
        { "id": 5, "author_name": "Ethan Black", "text": "Delivery was delayed but customer service handled it well. Response was a bit slow but overall good experience. Response was a bit slow but overall good experience.", "rating": 1, "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocLpL2Jer5Gz1JrskHzbpLpYCFggzbq3bZolbgoSNo3s9gBlvw=s128-c0x00000000-cc-rp-mo", "relative_time_description": "a hr ago" },
        { "id": 6, "author_name": "Fiona Green", "text": "Very user-friendly interface.", "rating": 0 },
        { "id": 7, "author_name": "George Blue", "text": "Support team was fantastic." },
        { "id": 8, "author_name": "Hannah Rose", "text": "Pricing could be more competitive." },
        { "id": 9, "author_name": "Isaac Gray", "text": "Product exceeded my expectations." },
        { "id": 10, "author_name": "Julia King", "text": "Response was a bit slow but overall good experience. Response was a bit slow but overall good experience. Response was a bit slow but overall good experience. Response was a bit slow but overall good experience."  },
        { "id": 11, "author_name": "Kyle White", "text": "Impressive attention to detail." },
        { "id": 12, "author_name": "Laura Black", "text": "Simple and effective service." },
        { "id": 13, "author_name": "Mason Reed", "text": "It took a while to understand the features." },
        { "id": 14, "author_name": "Nina Brown", "text": "Friendly and knowledgeable staff." },
        { "id": 15, "author_name": "Oliver Lee", "text": "The website layout is excellent." },
        { "id": 16, "author_name": "Parker Gray", "text": "My issue was resolved quickly." },
        { "id": 17, "author_name": "Quinn Black", "text": "I love the customization options." },
        { "id": 18, "author_name": "Rachel White", "text": "Could use more tutorials." },
        { "id": 19, "author_name": "Sam Green", "text": "Very intuitive and easy to navigate." },
        { "id": 20, "author_name": "Tina Brown", "text": "Overall, a pleasant experience." }
      ];  
      
    useEffect(() => {

    })
  return (
    <div className='company-wrapper'>
        <div className='title'>Clients Feedbacks</div>
        {/* <div className='feedback-container'>
            {data.map((item, index) => (
                <div className='feedback-card'>
                    <img className='review-img' src={item.profile_photo_url} alt={item.author_name} />
                    <div className='review-data'> 
                        <div className='review-auther-name'>{item.author_name}</div>
                        <div className='review-star-div'>
                            <div className='stars'>{item.rating === 5 ? (
                                    <div><IoIosStar className='' /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /></div>
                                ) : item.rating === 4 ? (
                                    <div><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStarOutline /></div>
                                ) : item.rating === 3 ? (
                                    <div><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /></div>
                                ) : item.rating === 2 ? (
                                    <div><IoIosStar /><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></div>
                                ) : item.rating === 1 ? (
                                    <div><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></div>
                                ) : (
                                    <div><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></div>
                                )
                            }
                            </div>
                            <div className='review-time'>{item.relative_time_description}</div>
                        </div>
                        <div className='review-text'>{item.text}</div>
                    </div>
                </div>
            ))} 
        </div> */}
        {/* <div> 
            {data.map((item, index) => (
                <div className='feedback-card1'>
                    <img className='review-img1' src={item.profile_photo_url} alt={item.author_name} />
                    <div className='review-data1'> 
                        <div className='review-auther-name1'>{item.author_name}</div>
                        <div className='review-star-div1'>
                            <div className='stars1'>{item.rating === 5 ? (
                                    <div><IoIosStar className='' /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /></div>
                                ) : item.rating === 4 ? (
                                    <div><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStarOutline /></div>
                                ) : item.rating === 3 ? (
                                    <div><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /></div>
                                ) : item.rating === 2 ? (
                                    <div><IoIosStar /><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></div>
                                ) : item.rating === 1 ? (
                                    <div><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></div>
                                ) : (
                                    <div><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></div>
                                )
                            }
                            </div>
                            <div className='review-time1'>{item.relative_time_description}</div>
                        </div>
                        <div className='review-text1'>{item.text}</div>
                    </div>
                </div>
            ))} 
        </div> */}
        <div className='feedback-container1'>
        <div
            className="feedback-left"
            onClick={() => swiperRef.current?.slidePrev()}
        >
            &#10094;
        </div>
        <div
            className="feedback-right"
            onClick={() => swiperRef.current?.slideNext()}
        >
            &#10095;
        </div>
            <Swiper
                className='feedback-swiper'
                modules={[Autoplay, Navigation]}
                navigation={false}
                onSwiper={(swiper) => (swiperRef.current = swiper)}
                loop={true}
                onSlideChange={() => console.log('slide change')}
                marginLeft={0}
                marginRight={0}
                marginTop={0}
                marginBotom={0}
                breakpoints={{ 
                    350: {slidesPerView: 1},
                    680: {slidesPerView: 2},
                    820: { slidesPerView: 3 },
                    1100: { slidesPerView: 4 },
                    1300: { slidesPerView: 5 }
                }}
            >
                {data.map((item, index) => (
                    <SwiperSlide key={index} className='feedback-swiperslide'>
                        <div className='feedback-card1'>
                        <   div className='stars1'>
                                {item.rating === 5 ? (
                                        <div><IoIosStar className='' /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /></div>
                                    ) : item.rating === 4 ? (
                                        <div><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStarOutline /></div>
                                    ) : item.rating === 3 ? (
                                        <div><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /></div>
                                    ) : item.rating === 2 ? (
                                        <div><IoIosStar /><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></div>
                                    ) : item.rating === 1 ? (
                                        <div><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></div>
                                    ) : (
                                        <div><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></div>
                                    )
                                }
                            </div>
                            <img
                                className="review-img1"
                                src={item.profile_photo_url || person }
                                alt={item.author_name || 'Anonymous'}
                            />
                            <div className='review-data1'> 
                                <div className='review-data-f1'>
                                <div className="review-auther-name1">{item.author_name || 'Unknown'}</div>
                                <div className="review-time1">{item.relative_time_description || 'Some time ago'}</div>
                                </div>
                                <div className='review-text-div'>
                                    <div className='review-text1'>{item.text}</div>
                                </div>
                        </div>
                    </div>
                </SwiperSlide>
            ))} 
            </Swiper>
        </div>
    </div>
  )
}

export default Feedback






// import React, { useEffect, useState } from 'react';

// const Feedback = () => {
//     const [reviews, setReviews] = useState([]);

//     useEffect(() => {
//         const fetchReviews = async () => {
//             try {
//                 // const response = await fetch('https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJu8NGHIMr_44RSyAJ0o85qIA&fields=reviews&key=AIzaSyDT8hJ0BFggjW1rp_gMxvAX8oIIIW9MQHU');
//                 const response = await fetch('https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJu8NGHIMr_44RSyAJ0o85qIA&fields=reviews&key=YOUR_API_KEY');

//                 console.log("llllllllllllllllllllll")
//                 // const data = await response.json();
//                 console.log("kkkkkkkkkk", response);
//                 // if (data.result && data.result.reviews) {
//                 //     setReviews(data.result.reviews);
//                 // }
//             } catch (error) {
//                 console.error("Error fetching reviews:", error);
//             }
//         };

//         fetchReviews();
//     }, []);

//     return (
//         <div className='company-wrapper'>
//             <div className='title'>Clients Feedbacks</div>
//             <div className='feedback-container'>
//                 {reviews.length > 0 ? (
//                     reviews.map((review, index) => (
//                         <div key={index} className='feedback-card'>
//                             <div className='feedback-title'>{review.author_name}</div>
//                             <div className='feedback'>{review.text}</div>
//                         </div>
//                     ))
//                 ) : (
//                     <p>No reviews available</p>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default Feedback;
